/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/**
 * Do feature detection, to figure out which polyfills needs to be imported.
 **/
async function loadPolyfills() {
  await import("smoothscroll-polyfill").then(module => module.polyfill())
  if (typeof window.IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

exports.onClientEntry = () => {
  loadPolyfills()
}
